import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/site/home/home.component';
import {EngenhariaComponent} from './components/site/engenharia/engenharia.component';
import { EnergiaRenovavelComponent } from './components/site/energia-renovavel/energia-renovavel.component';
import { FinanciaControladoriaComponent } from './components/site/financia-controladoria/financia-controladoria.component';
import {OrcamentoComponent} from './components/site/orcamento/orcamento.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'engenharia', component: EngenhariaComponent },
  { path: 'energia-renovavel', component: EnergiaRenovavelComponent },
  { path: 'financia-controladoria', component:  FinanciaControladoriaComponent },
  { path: 'orcamento', component:  OrcamentoComponent },
  // lugar inexistente? volta para o inicio
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
