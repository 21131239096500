<section class="banner_area ">
    <div class="banner_inner d-flex align-items-center">
        <div class="overlay bg-parallax banner-energia-renovavel" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
        <div class="container">
            <div class="banner_content text-center">
                <h2>Soluções em energias renováveis</h2>
                <div class="page_link">
                    <a routerLink="/">Home  →</a>
                    <a routerLink="/energia-renovavel">Energia Renovavel</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--================End Home Banner Area =================-->

<!--================Courses Area =================-->
<section class="courses_area p_120">
    <div class="container">
        <div class="main_title">
            <h2>Soluções em energias renováveis</h2>
            
        </div>
        <div class="row">
            <div class="col-xl text-center">
                <img src="assets/img/image4.jpg" style="max-width: 90%;" alt="imagem painel solar preto e branco">
              </div>

            <div class="col-xl">
              <h3 class="mt-4">Como Funciona a Energia Solar?</h3>
              <p class="">Proveniente dos raios de luz do Sol, a energia fotovoltaica é uma alternativa limpa e sem danos à natureza de se gerar energia elétrica. Atualmente, tal fonte de energia representa um método promissor de redução de custos para empresas e cidadãos a médio prazo. Mas como funciona? Entenda tudo sobre energia solar nas próximas linhas.
                O processo é simples. A luz do sol é captada diretamente por painéis solares fotovoltaicos conectados uns aos outros. Essa corrente armazenada é continuamente transportada a um inversor solar, espécie de painel instalado na parte interna que faz a conversão dos raios solares em energia elétrica para ser utilizada normalmente em sua casa ou empresa.</p>
            </div>
            
            
          </div>
    </div>
</section>

<!--================End Courses Area =================-->
<section class="mb-4">
    <div class="container">
        <div class="main_title">
            <h2>Orçamento</h2>
            
        </div>
        <div class="row">
            <div class="col-xl-7">
                
                <form (ngSubmit)="onSubmit()">
                    <div class="mt-10">
                        <label for="nome-empresa">Nome Empresa</label>
                        <input class="form-control" type="text" name= "nome_empresa" [(ngModel)]="cliente.nome_empresa" placeholder="Nome Empresa">
                    </div>
                    <div class="mt-10">
                        
                        <label for="nome-completo">Nome Completo</label>
                        <input class="form-control" type="text" name="nome_completo" [(ngModel)]="cliente.nome_cliente" placeholder="Nome Completo">
                    </div>
                    <div class="mt-10">
                        <label for="endereco-email">Endereço de e-mail</label>
                        <input class="form-control" type="text" name="endereco_email" [(ngModel)]="cliente.endereco_email" placeholder="">
                    </div>

                    <div class="mt-10">
                        <label for="nome-residencial/comercial">Endereço residencial/comercial</label>
                        <input class="form-control" type="text" name="nome-residencial/comercial" [(ngModel)]="cliente.endereco_residencial_comercial" placeholder="">
                    </div>

                    <div class="row">
                        <div class="mt-10 col-6">
                            <label for="contato-telefone">Telefone para contato</label>
                            <input class="form-control" type="text" name="telefone_contato" [(ngModel)]="cliente.telefone_contato" placeholder="">
                        </div>

                        <div class=" mt-10 col-6">
                            
                            
                                <label for="select-tipo-empresa">Tipo Instalação</label>
                                <select class="custom-select" name="tipo_instalacao" [(ngModel)]="cliente.tipo_instalacao">
                                    <option value="1">--</option>
                                    <option value="empresa">Empresa</option>
                                    <option value="industria">Indústria</option>
                                    <option value="agronegocio">Agronegocio</option>
                                    <option value="residencia">Residência</option>	
                                </select>
                            
                        </div>
                    </div>

                    <div class="row">
                        <div class="mt-10 col-6">
                            
                                <label for="select-tipo-telhado">Tipo de telhado</label>
                                <select class="custom-select" name="tipo_telhado" [(ngModel)]="cliente.tipo_telhado">
                                    <option value="1">--</option>
                                    <option value="empresa">Galvanizado</option>
                                    <option value="industria">Colonial</option>
                                    <option value="agronegocio">Fibrocimento</option>
                                    <option value="residencia">Laje</option>
                                    <option value="residencia">Solo</option>
                                
                                </select>
                            
                        </div>

                        <div class="mt-10 col-6">
                            
                                <label for="select-tipo-classe">Classe Instalação</label>
                                <select class="custom-select" name="classe_instalacao" [(ngModel)]="cliente.classe_instalacao">
                                    <option value="1">--</option>
                                    <option value="empresa">Monofásico</option>
                                    <option value="industria">Bifásico</option>
                                    <option value="agronegocio">Trifásico</option>
                                    
                                </select>
                            
                        </div>
                    </div>
                    <div class="custom-file mt-10">
                        <input type="file" class="custom-file-input " id="customFile">
                        <label class="custom-file-label " for="customFile">Anexar foto conta de luz</label>
                      </div>
                    


                    <div class="mt-10">
                        <label for="text-observacao">O que você busca?</label>
                        <textarea class="form-control" id="" rows="3" name="observacao" [(ngModel)]="cliente.observacao"></textarea>
                    </div>
                    <div class="mt-10">
                        <button type="submit" class="btn btn-primary">Solicitar</button>
                    </div> 
                </form>
            </div>

            <div class="col-xl-4 offset-xl-1">
               

            </div>
        </div>	
    </div>
</section>
