import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HomeComponent } from './components/site/home/home.component';
import { MenuComponent } from './components/layout/menu/menu.component';
import { EngenhariaComponent } from './components/site/engenharia/engenharia.component';
import { EnergiaRenovavelComponent } from './components/site/energia-renovavel/energia-renovavel.component';
import { FinanciaControladoriaComponent } from './components/site/financia-controladoria/financia-controladoria.component';
import { OrcamentoComponent } from './components/site/orcamento/orcamento.component';

@NgModule({
  declarations: [
    
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MenuComponent,
    EngenhariaComponent,
    EnergiaRenovavelComponent,
    FinanciaControladoriaComponent,
    OrcamentoComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
