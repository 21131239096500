<section class="banner_area">
    <div class="banner_inner d-flex align-items-center">
        <div class="overlay bg-parallax banner-finance" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
        <div class="container">
            <div class="banner_content text-center">
                <h2>Finanças e Controladoria</h2>
                <div class="page_link">
                    <a routerLink="/">Home →</a>	
                    <a routerLink="/financia-controladoria">Finanças e Controladoria</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--================End Home Banner Area =================-->

<!--================Courses Area =================-->
<section class="courses_area p_120">
    <div class="container">
        <div class="main_title">
            <h2>Serviços de Finanças e Controladoria           </h2>
            
        </div>
        <div class="row">
            <div class="col-xl">
           
                <ul class="unordered-list">
                    <li>Análise Econômico Financeira</li>
                    <li>Análise de Restrições Internas e Externas (TOC)</li>
                    <li>Análise de projetos de investimentos</li>
                    <li>Análise custo-volume-lucro, ponto de equilíbrio e GAO</li>
                    <li>Decisão de comprar ou fazer</li>
                    <li>Formação de preços</li>
                     <li>Decisão de preço quando varia o preço do insumos </li>
    
                </ul>
            </div>

            <div class="col-xl">
           
                <ul class="unordered-list">
                    <li>Decisão de aceitar ou rejeitar uma proposta</li>
                    <li>Decisão de preço considerando o retorno do investimento</li>
                    <li>Formação de estratégias operacionais para capital de giro</li>
                    <li>Demonstração do fluxo de caixa projetado</li>
                    <li>Demonstração de resultado projetada</li>
                    <li>Balanço Patrimonial projetado</li>
                    <li>Captação de recursos</li>

                </ul>
            </div>

        </div>
        

    


    </div>
</section>

<!--================End Courses Area =================-->
<section class="mb-4">
    <div class="container">
        <div class="main_title">
            <h2>Solicite Agora</h2>
            
        </div>
        <div class="row">
            <div class="col-xl-7">
                <form>
                    <div class=" mt-10">
                            <label for="select-tipo-empresa">Engenharia Desejada</label>
                            <select class="custom-select" onchange="displayAnswer(this.value)">
                                <option class="option" value="1">--</option>
                                <option value="eletric">Engenharia Elétrica</option>
                                <option value="mecanic">Engenharia Mecânica</option>
                            </select>
                        
                    </div>
                    <div class=" mt-10" id="servico-select" style="display: none;">
                        <div  id="" >
                            <select class="custom-select">
                                <option value="1">Serviço desejado</option>
                                <option value="2">Projetos Elétricos</option>
                                <option value="3">Laudos de Parecer de Valor</option>
                                <option value="4">Acompanhamento Técnico</option>
                                <option value="5">Cursos Energias Renováveis</option>
                                <option value="6">Treinamentos em NR-10</option>					
                            </select>
                        </div>
                    </div>

                    <div class=" mt-10" id="servico2-select" style="display: none;">
                        
                        <div  id="" >
                            
                            <select class="custom-select">
                                <option value="1">Serviço desejado</option>
                                <option value="2">Capacitação técnica para equipe de lubrificantes e lubrificação</option>
                                <option value="3">Capacitação técnica para Filtros e Elementos Filtrantes</option>
                                <option value="4">Consultoria e treinamentos em NR-12</option>
                                <option value="5">Planejamento e Projeto de Sistemas de Força Hidráulica</option>
                                <option value="6">Gerenciamento e Manutenção Produtiva</option>
                                <option value="6">Soluções em PCM</option>	
                                <option value="6">Soluções em bombeamento de água com uso de energia fotovoltaica</option>						
                            </select>
                        </div>
                    </div>

                    <div class="input-group-icon mt-10">
                        
                        <div  id="">
                            <label for="select-tipo-empresa">Tipo Pessoa</label>
                            <select class="custom-select"onchange="displayAnswer(this.value)">
                                <option value="2">--</option>
                                <option value="fisic">Física</option>
                                <option value="juridic">Jurídica</option>
                            </select>
                        </div>
                    </div>

                    <div class="mt-10" id="cpf-select" style="display: none">
                        <label for="numero-cpf">CPF</label>
                        <input class="form-control" type="text" placeholder="cpf">
                    </div>
                    <div class="mt-10" id="cnpj-select" style="display: none">
                        <label for="numero-cnpj">CNPJ</label>
                        <input class="form-control" type="text" placeholder="cpf">
                    </div>
                    
                    <div class="mt-10">
                        <label for="nome-completo">Nome Completo</label>
                        <input class="form-control" type="text" placeholder="">
                     </div>

                    <div class="mt-10">
                        <label for="contato-telefone">Telefone para contato</label>
                        <input class="form-control" type="text" placeholder="">
                    </div>

                    <div class="mt-10">
                        <label for="endereco-email">Endereço de e-mail</label>
                        <input class="form-control" type="text" placeholder="">
                    </div>

                    <div class="mt-10">
                        <label for="text-observacao">O que você busca?</label>
                        <textarea class="form-control" id="" rows="3"></textarea>                    </div>
                    <div class="mt-10">
                        <button type="submit" class="btn btn-primary">Solicitar</button>
                    </div>
                    
                </form>
            </div>
        </div>	
    </div>
</section>
