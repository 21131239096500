<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="assets/img/banner/banner3.jpg" alt="First slide">
            <div class="carousel-caption d-md-block">
                    <h1>Financie seu projeto fotovoltaico</h1>
                    <p>Possuímos as melhores taxas do mercado</p>
            </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="assets/img/banner/banner5.jpg" alt="Second slide">
            <div class="carousel-caption d-md-block">
                <h1>Conheça e adquira nossos serviços em Engenharia</h1>
                <p>Fornecemos as melhores soluções para você</p>
            </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="assets/img/banner/banner6.jpg" alt="Third slide">
            <div class="carousel-caption d-md-block">
                <h1>Indeciso em suas escolhas?</h1>
                <p>Oferecemos serviços de Finanças e Controladoria</p>
            </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

  







  


<!-- <section class="home_banner_area">
    <div class="banner_inner d-flex align-items-center">
        <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
        <div class="container">
            <div class="banner_content text-center ">
                <img src="assets/img/banner/banaux.png" class="img-fluid" style="max-width: 50%; margin-top: -15%;" alt="Responsive image">
                <div class="row d-flex justify-content-center">
                    <div  style="width: 50%; background-color:#183b6d; border-top-left-radius: 10px;
                    border-top-right-radius: 10px;" class="mobile-banner">
                        <h1 style="font-size: 30px; padding: 15px;">Financie seu projeto fotovoltaico <br/>com as melhores taxas do mercado</h1>
                        
                    </div>
                    
                </div>
                <div class="row d-flex justify-content-center">
                    <div style="width: 50%; background-color:#71923c; border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;" class="mobile-banner">
                        <h2 style="font-size: 30px; padding: 4px;">Produza sua própria energia</h2>
                    </div>
                </div>
                

            </div>
        </div>
    </div>
</section> -->

<!-- Seção serviços-->

<section id="target3" class=" p_120">
    <div class="container">
        <div class="main_title">
            <h2>Serviços</h2>
            
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4 pb-3">
                <!-- Copy the content below until next comment -->
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" style="background-image: url(assets/img/image1.jpg);"></div>
                  <!-- <div class="card-custom-avatar">
                    <img class="img-fluid" src="http://res.cloudinary.com/d3/image/upload/c_pad,g_center,h_200,q_auto:eco,w_200/bootstrap-logo_u3c8dx.jpg" alt="Avatar" />
                  </div> -->
                  <div class="card-body" style="overflow-y: auto">
                    <h4 class="card-title">Soluções em energias renováveis
                    </h4>
                    <p class="card-text"> Especialistas em Soluções Inteligentes MLPE 
                    </p>
                        <ul>
                            <li>Projetos</li>
                            <li>Instalação</li>
                            <li>Monitoramento</li>
                            <li>Consultoria Técnica</li>
                        </ul>
                  </div>
                  <div class="card-footer text-center" style="background: inherit; border-color: inherit;">
                    <!-- <a href="#" class="btn btn-primary">Option</a>
                    <a href="#" class="btn btn-outline-primary">Other option</a> -->
                    <a routerLink="/energia-renovavel" class="btn btn-primary button-cards">Conheça mais</a>
                  </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 pb-3">
                <!-- Copy the content below until next comment -->
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" style="background-image: url(assets/img/image2.jpg);"></div>
                  <!-- <div class="card-custom-avatar">
                    <img class="img-fluid" src="http://res.cloudinary.com/d3/image/upload/c_pad,g_center,h_200,q_auto:eco,w_200/bootstrap-logo_u3c8dx.jpg" alt="Avatar" />
                  </div> -->
                  <div class="card-body" style="overflow-y: auto">
                    <h4 class="card-title">Serviços de Engenharia</h4>
                    <ul style="padding-bottom: 40px;">
                        <li>Engenharia Elétrica </li>
                        <li>Engenharia Mecânica</li>
                        <li>Engenharia de Automação</li>
                        <li>Engenharia Civil</li>
                    </ul>
                    
                  </div>
                  <div class="card-footer text-center" style="background: inherit; border-color: inherit;">
                    <a routerLink="/engenharia" class="btn btn-primary button-cards">Conheça mais</a>
                  </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 pb-3">
                <!-- Copy the content below until next comment -->
                <div class="card card-custom bg-white border-white border-0">
                  <div class="card-custom-img" style="background-image: url(assets/img/image3.jpg);"></div>
                  <!-- <div class="card-custom-avatar">
                    <img class="img-fluid" src="http://res.cloudinary.com/d3/image/upload/c_pad,g_center,h_200,q_auto:eco,w_200/bootstrap-logo_u3c8dx.jpg" alt="Avatar" />
                  </div> -->
                  <div class="card-body" style="overflow-y: auto">
                    <h4 class="card-title">Serviços de Finanças e Controladoria	</h4>
                    <ul style="padding-bottom: 88px;">
                    <li>Finanças </li>
                    <li>Controladoria</li>
                    </ul>
                  </div>
                  <div class="card-footer text-center" style="background: inherit; border-color: inherit;">
                    <a routerLink="/engenharia" class="btn btn-primary button-cards">Conheça mais</a>
                  </div>
                </div>
            </div>
        
    </div>
    </div>
</section>

<!--Fim Seção Servicos-->



<section class="section_area" style="padding-bottom: 0px;">    
    
    <div class="container">
        <div id="target2" class="main_title">
            <h2>Objetivos</h2>
            
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <div class="box-part text-center">
                    <i class="fa fa-signal fa-3x" aria-hidden="true"></i>
                    <div class="title">
                        <h4>Resultados</h4>
                    </div>
                    <div class="text">
                        <span>Entregar resultados quantitativos e/ou qualitativos mostrando ao cliente através de indicadores e relatórios o antes e depois do trabalho realizado
                            .</span>
                    </div>	
                    
                 </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <div class="box-part text-center">
                    <i class="fa fa-usd fa-3x" aria-hidden="true"></i>
                    <div class="title">
                        <h4>Custos</h4>
                    </div>
                    <div class="text">
                        <span>Reduzir custos operacionais
                            .</span>
                    </div>	
                    
                 </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <div class="box-part text-center">
                    <i class="fa fa-road fa-3x" aria-hidden="true"></i>
                    <div class="title">
                        <h4>Decisões</h4>
                    </div>
                    <div class="text">
                        <span>Oferecer tomadas de decisões aos clientes baseadas nas melhores técnicas
                            .</span>
                    </div>	
                    
                 </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <div class="box-part text-center">
                    <i class="fa fa-rocket fa-3x" aria-hidden="true"></i>
                    <div class="title">
                        <h4>Soluções</h4>
                    </div>
                    <div class="text">
                        <span>Fornecer soluções técnicas independentes trazendo o que melhor existe no mercado na relação custo e benefício de acordo com a necessidade do cliente.</span>
                    </div>	
                    
                 </div>
            </div>
        </div>
    </div>	
</section>

<!-- Seção Como trabalhamos-->
<section id="target" class="courses_area p_120" style="padding-bottom: 0px;">
    <div class="container">
        <div  class="main_title">
            <h2>Como Trabalhamos</h2>
            <p>Com foco na solução da necessidade do cliente e através de mão de obra especializada geramos resultados quantitativos e/ou qualitativos as questões apresentadas a cada trabalho realizado.
                Com ampla experiência buscamos no mercado financeiro o recurso mais barato e no longo prazo para viabilizar a solução técnica de ponta a ponta ao cliente.
            </p>
        </div>
    </div>
</section>
<!--Fim como trabalhamos-->