<header class="header_area">
    <div class="top_menu row m0">
        <div class="container">
         <div class="float-left">
             <ul class="list header_social">
                 <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                 <li><a href="#"><i class="fa fa-twitter"></i></a></li>
             </ul>
         </div>
         <div class="float-right">
             <a class="dn_btn" href="tel:+553136177472">(31) 3617-7472</a>
             <a class="dn_btn" href="mailto:contato@ecoefin.com.br">contato@ecoefin.com.br</a>
         </div>
        </div>	
    </div>
    	
</header>