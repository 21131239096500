<div class="main_menu header_area">
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
            <!-- Brand and toggle get grouped for better mobile display -->
            <a class="navbar-brand logo_h mx-auto" href="index.html"><img src="assets/img/logo.png" alt=""></a>
            <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button> -->
            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse offset" id="navbarSupportedContent">
                <ul class="nav navbar-nav menu_nav ml-auto">
                    <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><a class="nav-link" routerLink="/" >Home</a></li> 
                    <li class="nav-item" [routerLinkActive]="['active']"><a class="nav-link"  routerLink="/energia-renovavel" >Energias renováveis</a></li>
                    <li class="nav-item" [routerLinkActive]="['active']"><a class="nav-link" routerLink="/engenharia">Engenharia</a></li>    
                    <li class="nav-item" [routerLinkActive]="['active']"><a class="nav-link"  routerLink="/financia-controladoria">Finanças e Controladoria</a></li>
                </ul>    
                        
            </div>
            
            
        </div>
    </nav>

    <div class="container menu-tab" style="display: none;">
        <div class="row text-center" style="height: 120px;">
            <div class="col-3 " [routerLinkActive]="['menu-item-tab']" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/">  
                <i class="fa fa-home" aria-hidden="true" style="font-size: 50px;"></i>
                <p>Home</p>
                </a>
            </div>
            <div class="col-3"[routerLinkActive]="['menu-item-tab']" >
                <a class="" routerLink="/energia-renovavel"> 
                <i class="fa fa-bolt" style="font-size: 50px;"></i>
                <p>Energia Renovavel</p>
                </a>
            </div>
            <div class="col-3"[routerLinkActive]="['menu-item-tab']">
                <a class="" routerLink="/engenharia">
                <i class="fa fa-wrench" style="font-size: 50px;"></i>
                <p >Engenharia</p>
                </a>
            </div>
            <div class="col-3" [routerLinkActive]="['menu-item-tab']">
                <a class="" routerLink="/financia-controladoria">
                <i class="fa fa-money" style="font-size: 50px;"></i>
                <p >Finanças e Controladoria</p>
                </a>
            </div>  
                    
        </div> 
    </div>    

</div>