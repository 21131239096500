import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-financia-controladoria',
  templateUrl: './financia-controladoria.component.html',
  styleUrls: ['./financia-controladoria.component.css']
})
export class FinanciaControladoriaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
