import { Component, OnInit } from '@angular/core';
import { Eng } from '../../../shared/eng';
import { PhotovoltaicService } from '../../../services/photovoltaic.service'


@Component({
  selector: 'app-energia-renovavel',
  templateUrl: './energia-renovavel.component.html',
  styleUrls: ['./energia-renovavel.component.css']
  
  
})
export class EnergiaRenovavelComponent implements OnInit {
  cliente: Eng;
  
  constructor(private servico: PhotovoltaicService) { }

  ngOnInit(): void {
    
     this.cliente = new Eng();
    
   
    
  }
 
  onSubmit() {
    // aqui você pode implementar a logica para fazer seu formulário salvar
    //this.servico.postData(this.cliente);
    this.servico.findPerson(this.cliente.endereco_email);
    

  }

  

}
