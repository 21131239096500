<section class="banner_area">
    <div class="banner_inner d-flex align-items-center">
        <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
        <div class="container">
            <div class="banner_content text-center">
                <h2>Serviços em Engenharia</h2>
                <div class="page_link">
                    <a routerLink="/">Home  →</a>
                    <a  routerLink="/engenharia">Engenharia</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="courses_area p_120">
    <div class="container">
        <div class="main_title">
            <h2>Serviços em Engenharia </h2>
            
        </div>
        <div class="row">
            <div class="col-xl">
              <h3 class="text-center">Engenharia Elétrica</h3>
              <ul class="unordered-list">
                <li>Projetos Elétricos</li>
                <li>Laudos de Parecer de Valor</li>
                <li>Acompanhamento Técnico</li>
                <li>Cursos Energias Renováveis</li>
                <li>Treinamentos em NR-10</li>
                <li>Serviços em telecomunicações</li>
                
            </ul>
            </div>
            <div class="col-xl">
                <h3 class="text-center">Engenharia Mecânica</h3>
                <ul class="unordered-list">
                    <li>Capacitação técnica para equipe de lubrificantes e lubrificação
                    </li>
                    <li>Capacitação técnica para Filtros e Elementos Filtrantes
                    </li>
                    <li>Consultoria e treinamentos em NR-12
                    </li>
                    <li>Planejamento e Projeto de Sistemas de Força Hidráulica
                    </li>
                    <li>Gerenciamento e Manutenção Produtiva
                    </li>
                    <li>Gestão de Frotas</li>
                    <li>Soluções em PCM
                    </li>
                    <li>Soluções em bombeamento de água com uso de energia fotovoltaica
                    </li>
                </ul>
            </div>

            <div class="col-xl">
                <h3 class="text-center">Engenharia Civil</h3>
                <ul class="unordered-list">
                    <li>Projetar e gerenciar obras civis; (orçamentista)</li>
                    <li>Elaborar relatórios técnicos. (Estrutural);</li>
                    <li>Coordenar projetos imobiliários. </li>
                    <li>Assessoria e implantação do SGQ.</li>
                    <li>Auditoria interna SGQ.</li>
                </ul>
            </div>
            <div class="col-xl">
                <h3 class="text-center">Engenharia de Automação</h3>
                <ul class="unordered-list">
                    <li>Implementação de sistemas de supervisão </li>
                    <li>Soluções para sistemas de bombeamento de água automatizado para irrigação</li>
                    <li>Soluções em automação rural</li>
                    <li>Sistemas de controle em processos de produção agrícola</li>
                    <li>Automação de máquinas agrícolas e industriais</li>
                </ul>
            </div>
            
          </div>
    </div>
</section>

<section class="mb-4">
    <div class="container">
        <div class="main_title">
            <h2>Solicite seu serviço</h2>
            
        </div>
        <div class="row">
            <div class="col-xl-7">
                <form>
                    <div class=" mt-10">
                            <label for="select-tipo-empresa">Engenharia Desejada</label>
                            <select class="custom-select" onchange="displayAnswer(this.value)">
                                <option class="option" value="1">--</option>
                                <option value="eletric">Engenharia Elétrica</option>
                                <option value="mecanic">Engenharia Mecânica</option>
                                <option value="civil">Engenharia Civil</option>
                                <option value="auto">Engenharia de Automação</option>
                            </select>
                        
                    </div>
                    <div class=" mt-10" id="servico-select" style="display: none;">
                        <div  id="" >
                            <label for="">Serviço Desejado</label>
                            <select class="custom-select">
                                <option value="1">Serviço desejado</option>
                                <option value="2">Projetos Elétricos</option>
                                <option value="3">Laudos de Parecer de Valor</option>
                                <option value="4">Acompanhamento Técnico</option>
                                <option value="5">Cursos Energias Renováveis</option>
                                <option value="6">Treinamentos em NR-10</option>					
                            </select>
                        </div>
                    </div>

                    <div class="mt-10" id="servico2-select" style="display: none;">
                        
                        <div  id="" >
                            <label for="">Serviço Desejado</label>
                            <select class="custom-select">
                                
                                <option value="1">Serviço desejado</option>
                                <option value="2">Capacitação técnica para equipe de lubrificantes e lubrificação</option>
                                <option value="3">Capacitação técnica para Filtros e Elementos Filtrantes</option>
                                <option value="4">Consultoria e treinamentos em NR-12</option>
                                <option value="5">Planejamento e Projeto de Sistemas de Força Hidráulica</option>
                                <option value="6">Gerenciamento e Manutenção Produtiva</option>
                                <option value="7">Soluções em PCM</option>	
                                <option value="8">Soluções em bombeamento de água com uso de energia fotovoltaica</option>						
                            </select>
                        </div>
                    </div>

                    <div class="mt-10" id="servico3-select" style="display: none;">
                        
                        <div  id="" >
                            <label for="">Serviço Desejado</label>
                            <select class="custom-select">
                                
                                <option value="1">Serviço desejado</option>
                                <option value="2">Projetar e gerenciar obras civis; (orçamentista)</option>
                                <option value="3">Elaborar relatórios técnicos. (Estrutural)</option>
                                <option value="4">Coordenar projetos imobiliários</option>
                                <option value="5">Assessoria e implantação do SGQ</option>
                                <option value="6">Auditoria interna SGQ</option>
                                					
                            </select>
                        </div>
                    </div>

                    <div class="mt-10" id="servico4-select" style="display: none;">
                        
                        <div  id="" >
                            <label for="">Serviço Desejado</label>
                            <select class="custom-select">
                                
                                <option value="1">Serviço desejado</option>
                                <option value="2">Implementação de sistemas de supervisão</option>
                                <option value="3">Soluções para sistemas de bombeamento de água automatizado para irrigação</option>
                                <option value="4">Soluções em automação rural</option>
                                <option value="5">Sistemas de controle em processos de produção agrícola</option>
                                <option value="6">Automação de máquinas agrícolas e industriais</option>
                                					
                            </select>
                        </div>
                    </div>

                    <div class="input-group-icon mt-10">
                        
                        <div  id="">
                            <label for="select-tipo-empresa">Tipo Pessoa</label>
                            <select class="custom-select"onchange="displayAnswer(this.value)">
                                <option value="2">--</option>
                                <option value="fisic">Física</option>
                                <option value="juridic">Jurídica</option>
                            </select>
                        </div>
                    </div>

                    <div class="mt-10" id="cpf-select" style="display: none">
                        <label for="numero-cpf">CPF</label>
                        <input class="form-control" type="text" placeholder="cpf">
                    </div>
                    <div class="mt-10" id="cnpj-select" style="display: none">
                        <label for="numero-cnpj">CNPJ</label>
                        <input class="form-control" type="text" placeholder="cpf">
                    </div>
                    
                    <div class="mt-10">
                        <label for="nome-completo">Nome Completo</label>
                        <input class="form-control" type="text" placeholder="">
                     </div>

                    <div class="mt-10">
                        <label for="contato-telefone">Telefone para contato</label>
                        <input class="form-control" type="text" placeholder="">
                    </div>

                    <div class="mt-10">
                        <label for="endereco-email">Endereço de e-mail</label>
                        <input class="form-control" type="text" placeholder="">
                    </div>

                    <div class="mt-10">
                        <label for="text-observacao">O que você busca?</label>
                        <textarea class="form-control" id="" rows="3"></textarea>                    </div>
                    <div class="mt-10">
                        <button type="submit" class="btn btn-primary">Solicitar</button>
                    </div>
                    
                </form>
            </div>

            <div class="col-xl-4 offset-xl-1">
                <h3 class="text-center">Contato</h3>
                <p>
                    Eng. Douglas Baldez – (31) 9 9146- 6033<br/>
                    CREA-MG 174682/D 
                </p>

                <p>
                    Eng. Jessé Alves – (31) 9 8719-9205<br/> 
                    CREA-MG 236606/D
                </p>
                
            </div>
        </div>	
    </div>
</section>
