export class Eng {
    
        id: number;
        nome_empresa: String;
        nome_cliente: String;
        endereco_email: String;
        endereco_residencial_comercial:String;
        telefone_contato:String;
        tipo_instalacao: String;
        tipo_telhado: String;
        classe_instalacao: string;
        observacao: String;
    

}
