<footer class="pt-4" style="background-color: #71923c;">

	<!-- Footer Links -->
	<div class="container text-center text-md-left">
  
	  <!-- Footer links -->
	  <div class="row text-center text-md-left mt-3 pb-3">
  
		<!-- Grid column -->
		<div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3" style="color: white;">
			<a class="navbar-brand logo_h" href="index.html"><img src="assets/img/logo_bottom.png" style="max-width: 69%; margin-top: -20px;" alt=""></a>
		  <p>Empresa de engenharia e finanças com profissionais associados especializada em soluções técnicas para o dia a dia de propriedades rurais, comércios, indústrias e residências.
		</p>
		</div>
		<!-- Grid column -->
  
		<hr class="w-100 clearfix d-md-none">
  
		<!-- Grid column -->
		<div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
		  <h6 class="text-uppercase mb-4 font-weight-bold" style="color: white;">Serviços</h6>
		  
			<p>
				<a  routerLink="/energia-renovavel">Soluções em energias renováveis</a>
			  </p>
			  <p>
				<a routerLink="/engenharia">Serviços de Engenharia	</a>
			  </p>
			  <p>
				<a routerLink="/financia-controladoria">Serviços de Finanças e Controladoria</a>
			  </p>
		  
		 
		</div>
		<!-- Grid column -->
  
		<hr class="w-100 clearfix d-md-none">
  
		<!-- Grid column -->
		<!-- <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
		  <h6 class="text-uppercase mb-4 font-weight-bold" style="color: white;">Mapa</h6>
		  <p>
			<a href="#target">Como trabalhamos</a>
		  </p>
		  <p>
			<a href="#target2">Objetivos</a>
		  </p>
		  <p>
			<a href="#target3">Serviços</a>
		  </p>
		  <p>
		  </p>
		</div> -->
  
		
		<hr class="w-100 clearfix d-md-none">
  
		
		<div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
		  <h6 class="text-uppercase mb-4 font-weight-bold" style="color: white;">Contato</h6>
		  <p>
			<i class="fa fa-home mr-3"></i>Rua Sabará, 09, Lojas 1 e 2 - Centro, Ipatinga - MG</p>
		  <p>
			<i class="fa fa-envelope mr-3"></i>contato@ecoefin.com.br</p>
		  <p>
			<i class="fa fa-phone mr-3"></i>(31) 3617-7472  </p>
		  <p>
			
		</div>
		
  
	  </div>
	  
  
	  <hr>
  

	  <div class="row d-flex align-items-center">
  
		<div class="col-md-7 col-lg-8">
  
		  <!--Copyright-->
		  <p class="text-center text-md-left"> Ecoefin 2020 © TODOS OS DIREITOS RESERVADOS.
		  </p>
  
		</div>

	  </div>
	  
	</div>
	
  
  </footer>
