<div class="container">
    <div class="row">
        <div class="col d-flex justify-content-between align-items-center">
            <h1 class="">Formulário de orçamento</h1>
            <img class="" src="assets/img/logo.png" alt="">
        </div>
    </div>
</div>

<div class="container">
    <p class="RowTitle">Dados do Consultor</p>
    <p class="RowSemiTitle">Solicitaçao:</p>
    <div class="row">       
        <div class="row">
            
            <div class="col d-flex justify-content-between align-items-center RadioButton ml-4">
                <input type="radio" class="RadioButton__input mr-1" id="" name="" value="novo-orcamento">
                <label for="novo-orcamento " class="RadioButton__label mr-4">Novo Orçamento</label><br>

                <input type="radio" class="RadioButton__input mr-1" id="" name="" value="novo-orcamento">
                <label for="novo-orcamento" class="RadioButton__label">Novo Orçamento</label><br>
            </div>
        </div>
        <div class="row">
            <div class=col>
                
            </div>
        </div>
    </div>
</div>
