import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CompileShallowModuleMetadata, removeSummaryDuplicates } from '@angular/compiler';







@Injectable({
  providedIn: 'root'
})
export class PhotovoltaicService {

  token = "";

  url = 'http://localhost:3000/orcamento'; // api rest fake
  url2 = ''

  constructor(private httpClient: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }

  // getData(data){
  //   // return this.httpClient.get(this.url).subscribe(data =>{console.log('Teste', data)});
  //   console.log(data);
  // }

  postData(cliente){


      var titleDeal = cliente.nome_cliente;
      var personId = 53990;
      var stageId = 82;
      var personEmail = cliente.email;
      this.findPerson(personEmail);
      
      const body = {title: titleDeal, person_id: personId, stage_id: stageId}
      
    
    
      this.httpClient.post(this.url2, body, this.httpOptions).subscribe();
  }

  findPerson(email){
      var url = '';
      
      console.log(`${ url }${ email }`);
      var teste;
      this.httpClient.get(`${ url }${ email }`);

      
     
  }

  
}
